import React from 'react'
import {Link} from 'gatsby'

interface AnswersProps{
  answers: {
    title: string
    fields: {
      slug: string
    }
  }[]
}

export const Answers: React.FC<AnswersProps> = ({answers}) => {
  return <ul>
    {answers.map((answer) => {
      return <li key={answer.fields.slug}><Link to={answer.fields.slug}>{answer.title}</Link></li>
    })}
  </ul>
}