import React from 'react'
import {Link} from 'gatsby'
import styled from '@emotion/styled'

import {BoxGrid} from './grid'
import {Content} from './content'

const Question = styled.div`
  text-align:center;
`

interface QuestionsProps{
  questions: {
    title: string
    description: string
    fields: {
      slug: string
    }
  }[]
}

export const Questions: React.FC<QuestionsProps> = ({questions}) => {
  return <BoxGrid>
    {questions.map((question) => {
      return <Question key={question.fields.slug}>
        <h2><Link to={question.fields.slug}>{question.title}</Link></h2>
        <Content html={question.description} />
      </Question>
    })}
  </BoxGrid>
}