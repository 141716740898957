import React from 'react'
import {Link} from 'gatsby'

export const Breadcrumbs: React.FC<{breadcrumbs: string}> = ({breadcrumbs}) => {
  const crumbs = breadcrumbs.split('#')

  return <p>
    <Link to="/">Home</Link> / 
    {crumbs.map((crumb) => {
      const [title, slug] = crumb.split('|')

      if(slug){
        return <><Link to={slug}>{title}</Link> / </>
      }

      return <>{title}</>
    })}
  </p>
}