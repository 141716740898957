import React from 'react'
import styled from '@emotion/styled'

const LayoutGrid = styled.div`
  display:grid;
  grid-template-columns:minmax(1.2em, 1fr) minmax(auto, 750px) minmax(1.2em, 1fr);
`

export const Layout: React.FC = ({children}) => {
  return <LayoutGrid>
    <div style={{gridColumn: 2}}>{children}</div>
  </LayoutGrid>
}

export const BoxGrid = styled.div`
  display:grid;
  grid-template-columns:repeat(auto-fit, minmax(200px, 1fr));
  grid-gap:10px;
`