import React from 'react'
import styled from '@emotion/styled'
import {Global} from '@emotion/core'
import {Link} from 'gatsby'

import {Layout, BoxGrid} from './grid'

const HeaderStrip = styled.header`
  background-color:#024b8f;
  line-height:50px;
  color:#fff;

  a{
    color: #fff;
    font-weight: bold;
  }
`

export const Header: React.FC = () => {
  return <HeaderStrip>
    <Global styles={`
      body{
        margin:0;
        padding:0;
      }

      a{
        text-decoration:none;
        color:#024b8f;
        transition:color 0.2s;

        &:hover{
          color:#fc5f01;
        }
      }
    `} />
    <Layout>
      <BoxGrid>
        <div><Link to={'/'}>Ed-IT Solutions Self Help</Link></div>
        <div style={{textAlign: 'right'}}>
          <Link to={'/about'}>About</Link>
          &nbsp;|&nbsp;
          <a href="https://www.ed-itsolutions.com">Ed-IT Solutions</a>
        </div>
      </BoxGrid>
    </Layout>
  </HeaderStrip>
}